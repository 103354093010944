import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import checkURL from '../functions/checkURL'
import { Redirect } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import ReactHtmlParser from 'react-html-parser';
import * as actionTypes from '../../store/actions';
import ShareModal from '../share'
import "@fortawesome/fontawesome-free/css/all.css";
import Loader from '../loader.js'
import '../../css/mediaDetail.css'
//import { continueStatement } from '@babel/types';

import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();
/**
 * this is version 2 of media detail
 * for the media(image, video, audio) that users clicked
 * <br>
 * Functionality:
 * * share the current url by using the ShareModal component
 * * enter fullscreen mode
 * * go back to the timeline page
 * * click the media to hide the description
 * Image:
 * * users can view the related images
 * <br>
 * Video/Audio:
 * * when users click play, then the description will be slided out 
 */
class MediaDetail extends Component {

    /**
     * bind all of the event handlers and member functions
     * @param {*} props - the data been passed from the Redux
     * 
     */
    constructor(props) {
        super(props)

        /**
         * the state object to store all data to display on the page
         * * currentHash: the hash table for the current timeline
         * * share: the boolean variable that checks either to display or hide the share modal
         * * isLoading: the boolean variable that checks either to display or hide the loading icon
         * * hideInfo: the boolean variable that checks either to display or hide the description for the media
         */
        this.state = {
            currentHash: this.props.hash['timeline' + this.props.match.params.timelineID],
            share: false,
            isLoading: true,
            hideInfo: false
        }
        this.handleFullScreen = this.handleFullScreen.bind(this)
        this.handleEscFullScreen = this.handleEscFullScreen.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)
        this.handleShare = this.handleShare.bind(this)
        this.getRelatedImgs = this.getRelatedImgs.bind(this)
        this.getEntryDetails = this.getEntryDetails.bind(this)
        this.handleLoading = this.handleLoading.bind(this)
        this.handleHideInfo = this.handleHideInfo.bind(this)
        this.formatRender = this.formatRender.bind(this)


    }
    
    scrollRight(e) {
        e.preventDefault()
        var content = document.getElementById('scrollable-image-thumbnails')
        var box = document.getElementById('relatedImgContainer')
        var scrollStep = 100
        var rightButton = document.getElementById('scrollable-right')
        var leftButton = document.getElementById('scrollable-left')
        let sl = content.scrollLeft,
        cw = content.scrollWidth,
        bw = box.scrollWidth
        //console.log(content.scrollLeft, content.scrollWidth)
        //console.log(sl," ",scrollStep," ")
        if ((sl + bw) >= cw) {
            rightButton.style.display = 'none'
            leftButton.style.display = 'block'
            content.scrollTo(cw, 0)
        } else {
            leftButton.style.display = 'block'
            content.scrollTo((sl + scrollStep), 0)
        }
    }
    scrollLeft(e) {
        //console.log('Click left')
        e.preventDefault()
        var content = document.getElementById('scrollable-image-thumbnails')
        var scrollStep = 100
        var leftButton = document.getElementById('scrollable-left')
        var rightButton = document.getElementById('scrollable-right')    
        let sl = content.scrollLeft
            
        if ((sl - scrollStep) <= 0) {
            content.scrollTo(0, 0)
            leftButton.style.display = 'none'
            rightButton.style.display = 'block'
        } else {
            content.scrollTo((sl - scrollStep), 0)
            rightButton.style.display = 'block'
        }
    }



    /**
     * This is one of the react life cycle hook, it will be running after the render function, then DOM will be displaying the components
     * <br>
     * In this function, I add exit full screen by using escape key event handler to the whole page 
     */
    componentDidMount() {
        this.horizontalImgContainer  = document.querySelector('.scrollable-image-thumbnails')
        document.addEventListener('webkitfullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('mozfullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('fullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('MSFullscreenChange', this.handleEscFullScreen, false)

        //document.addEventListener('mousemove', this.handleMouseMove); 
    }

    /**
     * This is one of the react life cycle hook, it will be running before the component is destroyed
     * <br>
     * In this function, I remove all of the event listeners that I added in the componentDidMount function
     */
    componentWillUnmount(){
        document.addEventListener('webkitfullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('mozfullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('fullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('MSFullscreenChange', this.handleEscFullScreen, false) 
    }

    /**
     * This function handles entering and exiting fullscreen by targeting the entire HTML and then
     * make that fullscreen.
     * 
     * @param {Object} e - event
     */
    handleFullScreen(e) {
        this.props.onScrollTo(undefined)
        e.preventDefault()
        const fullScreenDiv = document.querySelector('body')
        if(this.props.isFullScreen){
            if(document.exitFullscreen){
                document.exitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        }else{
            if (fullScreenDiv.requestFullscreen) {
                fullScreenDiv.requestFullscreen()
            } else if (fullScreenDiv.mozRequestFullScreen) { /* Firefox */
                fullScreenDiv.mozRequestFullScreen()
            } else if (fullScreenDiv.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                fullScreenDiv.webkitRequestFullscreen()
            } else if (fullScreenDiv.msRequestFullscreen) { /* IE/Edge */
                fullScreenDiv.msRequestFullscreen()
            }
        }
        this.props.onFullScreen(!this.props.isFullScreen)
    }

    /**
     * This function handles setting the Redux state - isFullScreen
     */
    handleEscFullScreen() {
        this.props.onScrollTo(undefined)
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            this.props.onFullScreen(false)
        }else{
            this.props.onFullScreen(true)
        }
    }
    
    /**
     * This function handles returning to the timeline page from the media detail page.
     * It stores the current entry ID to Redux (position) so that it can be accessed in the timeline page.
     */
    handleGoBack() {      
        this.props.onScrollTo(this.props.match.params.entryid)
        this.props.history.push(`/timeline/` + this.props.match.params.timelineID)
    }
    handleChange = (e) => {
        if (e.key === 'Enter') {
          this.handleGoBack();
        }
      }

    /**
     * This function handles the opening and closing of the share modal.
     */
    handleShare() {
        this.setState({
            ...this.state,
            share: !this.state.share
        })
    }

    /**
     * This function is used to retrieve all of the related images of an image if there are any.
     * 
     * @return {Object} Returns an array that contains all of the related images.
     */
    getRelatedImgs() {
        let entryId = this.props.match.params.entryid
        let related = []
        Object.keys(this.state.currentHash).map((entry, i) => {
            if(String(entry).substr(0, entry.indexOf("_")) ===  String(entryId)) {
                related.push(entry)
            }
            return 0
        })
        return related
    }

    /**
     * This function takes in the entryID and mediaID as the params to construct a key
     * that will be used in the hashtable to retrieve the metadata for a media. If the media is an audio, then
     * it will only use the entryID as the key.
     * 
     * @param {String} entryID 
     * @param {String} mediaID
     * @return {Object} Returns an Object that contains the metadata of the media that was found.
     */
    getEntryDetails(entryID, mediaID) {
        let db = this.state.currentHash
        let key
        if(mediaID) {
            key = entryID + '_' + mediaID
            return db[key]
        } 
        // else {
        //     return db[entryID]
        // }
    }

    /**
     * This function handles the loading state of images in the media detail page.
     * So, the image will only render after it is completely loaded. If it is not, then
     * the loading icon will be displayed until the image is completely loaded.
     */
    handleLoading() {
        this.setState({
            ...this.state,
            isLoading: false
        })
    }

    /**
     * This function is used to handle the hiding of the text modal, so that the page focuses 
     * in on the image. For audios and videos, the text will hide once the audio/video begins to play
     * and it will come back either after the audio/video is done or is paused.
     */
    handleHideInfo() {
        this.setState({
            ...this.state,
            hideInfo: !this.state.hideInfo
        })
    }

    /**
     * This function handles the formatting of the media detail page based on the media type.
     * It will first call getEntryDetails to retrieve the metadata of the media being requested.
     * If the media is an image, then the function will first pull all of the related images and save that to a variable
     * call relatedImgs. Else, it will just format the data based on the type.
     * 
     * Side note: the function ReactHtmlParser handles converting a string with html tags in it to actual html.
     * Here's the link to the function's doc: https://www.npmjs.com/package/react-html-parser
     * 
     * @param {String} type - The media type of the media being viewed.
     * @return {Object} Return an JSX Object.
     */
    formatRender(type) {
        let relatedImgs = []
        let info = this.getEntryDetails(this.props.match.params.entryid, this.props.match.params.mediaid)
        //console.log(info)
        let buttonGroup = (
            <div className='buttons'>
                <header className="line">
                    <span id="closeModal" className="close-modal" tabIndex={0} onKeyPress={this.handleChange} onClick={this.handleGoBack} title="Go Back"></span>
                    <span className="time"></span>
                    <nav>
                        <ul>
                            <li className="share-button right standardButton">
                                <Button 
                                    
                                    className="standardButton"
                                    onClick={this.handleShare}
                                >
                                    <span> Share &nbsp; <i className="fas fa-share-alt"></i> </span>
                                </Button>
                            </li>
                            <li className="fs-button right standardButton">                           
                                <Button 
                                    
                                    className="standardButton"
                                    onClick={this.handleFullScreen}
                                >
                                        {
                                            !this.props.isFullScreen ? 
                                            (<i title="Full Screen" className="fas fa-expand-arrows-alt" />) 
                                            : (<i className="fas fa-compress-arrows-alt" title='Exit Full Screen'></i>)
                                        }
                                </Button>
                            </li>
                        </ul>                       
                    </nav>
                </header>
            </div>
        )
        
        // format for images
        if(type === 'images') {
            relatedImgs = this.getRelatedImgs() // retrieve the related images
            // map out the related images
            let imgs = relatedImgs.map((image, i) => {
                let id = image.substr(image.indexOf("_") + 1)
                return(
                    <div key={this.state.currentHash[String(image)].Src} className='relatedImg' data-index={this.state.currentHash[String(image)].Src}>
                        <Link aria-label={this.state.currentHash[String(image)].Alt} to={`${id}`} >
                            <img 
                                src={this.state.currentHash[String(image)].Src} 
                                alt={this.state.currentHash[String(image)].Alt}
                            />
                        </Link>
                    </div>
                    
                )
            })
            
            return (
                <Modal 
                    open={true}
                    onClose={this.handleGoBack}
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                >  
                    <div className='modalContainer'>
                        <img 
                            src={info.Src} 
                            alt={info.Alt} 
                            onLoad={this.handleLoading} // only display the image if it is completely loaded.
                            style={this.state.isLoading ? {display: 'none'} : {}}
                            onClick={this.handleHideInfo}
                        />
                        {/* If the user clicks on the image, then the text will slide out of the screen. */}
                        <div className='modalInfo' 
                        style={this.state.hideInfo ? {animation: 'slideOut 700ms linear forwards'} : {animation: 'slideIn 700ms linear forwards'}}
                        data-simplebar
                        data-simplebar-auto-hide="false"
                        >
                            {buttonGroup}
                            <div className='infoText details-description'>
                                <p className="date">{info.time}</p>
                                <p className="title">{info.Title}</p>
                                <div className='description'>{ ReactHtmlParser(info.Description) }</div>
                                {imgs.length > 1 ? <div id='relatedImgContainer' className='relatedImgContainer unselectable'>
                                        <div style={{width:'100%'}} id="scrollable-image-thumbnails" className="scrollable-image-thumbnails">
                                            {imgs}
                                        </div>
                                        {imgs.length > 3 ? <div><div id="scrollable-left" className="scrollable-left" onClick={this.scrollLeft}>&lt;</div>
                                        <div id="scrollable-right" className="scrollable-right" onClick={this.scrollRight}>&gt;</div></div> : null} 
                                        </div> : null}            
                            </div> 
                        </div>
                    </div>
                </Modal>
            )
        }

        if(type === 'audios') {
            // format for audios
            var audio = document.querySelector(".modalContainer audio");
            if(!audio){
                this.handleLoading()  
            }
            return(
                <Modal 
                    open={true}
                    onClose={this.handleGoBack}
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    className="modal-container"
                >                
                    <div className='modalContainer'>
                        <div>
                            <audio  
                                src={info.Src} 
                                controls
                                controlsList="nodownload"
                                autoplay="autoplay" 
                                //onPlay={this.handleHideInfo} // hides the information modal when the audio is playing
                                //onPause={this.handleHideInfo} // brings back the information modal when the audio is paused or done
                            >
                                Your Browser Does Not Support Audio
                            </audio>
                        </div>
                        <div className='modalInfo' style={this.state.hideInfo ? {animation: 'slideOut 700ms linear forwards'} : {animation: 'slideIn 700ms linear forwards'}}>
                            {buttonGroup}
                            <div className='infoText details-description'>
                                <p className="date">{info.time}</p>
                                <p className="title">{info.Title}</p>
                                <div className='description'>{ ReactHtmlParser(info.Description) }</div>
                            </div>
                        </div>
                    </div>      
                </Modal>
            )
        }

        if(type === 'videos') {
            // format for videos
            var video = document.querySelector(".modalContainer video");
            if(!video){
                this.handleLoading()   
            }
            return (
                <Modal 
                    open={true}
                    onClose={this.handleGoBack}
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    className="modal-container"
                >  
                    <div className='modalContainer' >
                        <video 
                            width="320" 
                            height="240" 
                            controls
                            controlsList="nodownload"
                            autoplay="autoplay" 
                            onPlay={this.handleHideInfo} // hides the information modal when the video is playing
                            onPause={this.handleHideInfo} // brings back the information modal when the video is paused or done
                        >
                            <source src={(info.Src).replace('flv', 'mp4')} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <div className='modalInfo' style={this.state.hideInfo ? {animation: 'slideOut 700ms linear forwards'} : {animation: 'slideIn 700ms linear forwards'}}>
                            {buttonGroup}
                            <div className='infoText details-description'>
                            <p class="date">{info.time}</p>
                            <p class="title">{info.Title}</p>
                            <p className='description'>{ ReactHtmlParser(info.Description) }</p>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }
    }

    /**
     * * The entry point for the media detail component
     * * Display the description modal on the right side of the page
     * * Display the corresponding media
     * @return {Object} a JSX object that will be displaying on the media detail page
     */
    render() {
        let validURL = checkURL(this.props.match.params, this.props.timeline, this.props.hash) // checks if the URL entered is valid

        // if it is valid, then render the appropriate page
        if(validURL) {
            let modal = this.formatRender(this.props.match.params.mediaType)
            //let modalDiv = document.querySelector('body')
            return (
                <div>
                    {this.state.isLoading ? <Loader /> :  null}
                    {modal}
                    < ShareModal share={this.state.share} handleShare={this.handleShare} />
                </div>      
            )
        } else { // else redirect to error page
            return < Redirect to='/pagenotfound' push={true} />
        }
    }
}

/**
 * This function maps all of the Redux states to this component so that you can
 * access the Redux state in this component's props. For example, to access the Redux timeline 
 * state, you'd use this.props.timeline.
 * 
 * @param {Object} state
 * @returns {Object} Returns the Redux states in a form of props. 
 */
const mapStateToProps = state => {
    return {
        timeline: state.timeline,
        hash: state.hash,
        isFullScreen: state.isFullScreen
    };
};

/**
 * This function maps all of the Redux methods to this component. They can be accessed in
 * this.props like the Redux states.
 * 
 * @returns {Object} Returns the Redux state handlers in your props.
 */
const mapDispatchToProps = dispatch => {
    return {
        onScrollTo: (position) => dispatch({type: actionTypes.SCROLL_TO, payload: position}),
        onFullScreen: (isFullScreen) => dispatch({type: actionTypes.GET_ISFULLSCREEN, payload: isFullScreen})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaDetail);