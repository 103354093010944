import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import babelPolyfill from 'babel-polyfill';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import ClickyTracking from './components/ClickyTracking';


const store = createStore(reducer)
window.store = store

import { datadogRum } from '@datadog/browser-rum';


if (!window.datadogRumInitialized) {
    datadogRum.init({
	    applicationId: '5ae2ba63-8c31-430a-b2ee-5c3c2a8551d3',
	    clientToken: 'puba541a6bc52cff717935517e37fb39a27',
	    // `site` refers to the Datadog site parameter of your organization
	    // see https://docs.datadoghq.com/getting_started/site/
	    site: 'datadoghq.com',
	    service: 'timeline',
	    env: 'prod',
	    // Specify a version number to identify the deployed version of your application in Datadog
	    // version: '1.0.0', 
	    sessionSampleRate: 100,
	    sessionReplaySampleRate: 10,
	    trackUserInteractions: true,
	    trackResources: true,
	    trackLongTasks: true,
	    defaultPrivacyLevel: 'mask-user-input',
	});

    datadogRum.startSessionReplayRecording();

    window.datadogRumInitialized = true;
}

//App Data Source (drupal or local) 
ReactDOM.render(<Provider store={store}><ClickyTracking siteId="101063790" /><App source='drupal' /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
