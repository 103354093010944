import React from 'react'
import '../App.css'

/**
 * This is the component to display the loading icon
 * @return {Object} Return an JSX Object.
 */
export default function Loader(){
    return (
        <div className="loader"></div>
    )
}
