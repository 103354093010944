import React, { Component } from 'react'
import Header from '../components/header'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/footer'
import timelineLabels from '.././data/json_label.json'
import { connect } from 'react-redux'
import * as actionTypes from '../store/actions'
import ReactHtmlParser from 'react-html-parser'
import { withStyles, ThemeProvider, createStyles  } from '@material-ui/styles';
import { Redirect } from 'react-router-dom'



const styles = theme => ({
    button: {
        color: 'white',
        border: '1px solid white',
        'margin-right': '10px',
        '&:hover': {
            backgroundColor: 'rgba(0, 135, 255, 0.75)'
        }
    },
});



class Home extends Component {
    constructor(props){
        super(props)
        this.state = {  
            isLoading: true,
            currentTimeline: 'Choose A Timeline',
            timelines: []
        }
        this.handleChangeTimeline = this.handleChangeTimeline.bind(this)
        this.resetPosition = this.resetPosition.bind(this)
    }

    /**
     * This function is the event handler for users to swtich between timelines through the dropdown next to the logo image
     */
    handleChangeTimeline(event) {
        this.props.onScrollTo('beginning')
        let path = ``
        this.setState({
            ...this.state,
            currentTimeline: event.target.value,
            isLoading: true
        }, () => {
            Object.keys(this.props.timelineNames).map((data) => {
                //console.log("state: ",this.state)
                if(this.props.timelineNames[data].name.toLowerCase() === this.state.currentTimeline.toLowerCase()) {
                    path = `timeline/${this.props.timelineNames[data].tid}`
                    console.log("Path: ",path)
                    this.props.history.push(path)

                }
            })
        })
    }
    resetPosition(){
        this.props.onScrollTo('beginning')
    }
    
    getTimelineNames() {
        let currentPath
        if(this.props.timelineNames['timeline' + this.props.match.params.timelineID]) {
            currentPath = this.props.timelineNames['timeline' + this.props.match.params.timelineID].name
        }
        // let currentPath = this.props.timelineNames['timeline' + this.props.match.params.timelineID].name 
        // currentPath = currentPath.split('_').join(' ')
        let timelineList = []
        // timelinesInfo.Response.Timelines.Timeline.map((item) => {
        //     timelineList.push(item["_Name"])
        //     return true
        // })
    
        Object.keys(this.props.timelineNames).map((data) => {
            timelineList.push(this.props.timelineNames[data].name)
            return true
        })
        return timelineList
    }


   
    render() {
        //console.log('fdsafdsa=====',this.state.currentTimeline)
        const { classes } = this.props
        
        return (
            <div className='home'>
            <Header params={this.props.match.params} 
                    timelines={this.getTimelineNames(this.props)} 
                    currentTimeline={this.state.currentTimeline}
                    handleChangeTimeline={this.handleChangeTimeline}
                />
        
            <h1 id='homeTitle'><strong>9/11 Interactive Timelines</strong></h1>
            <div className="home-container">
                {Object.keys(this.props.timelineNames).map((timeline,i) => {
                    let data = this.props.timelineNames[timeline]
                    return (
                        <div key={i} className="home-content">    
                            <h5 className='homeSub'>{data.name}</h5>
                            <div className='homeDesc'>{ReactHtmlParser(data.description__value)}</div>
                        </div>
                    )
                })}
            
                <div id='timeButton' className = {classes.timeButton}>
                    {Object.keys(this.props.timelineNames).map((timeline,i) => {
                        let data = this.props.timelineNames[timeline]
                        return (
                            <Link key={data.tid} to={`timeline/${data.tid}`}><Button tabIndex='-1' variant='outlined' className={classes.button}>{data.name}</Button></Link>
                        )
                    })}
                </div>         
            </div>
            <Footer history={this.props.history} location={this.props.location}/>
            </div>
        )   
        
    }
}


const mapStateToProps = state => {
    return {
        timelineNames: state.timelineNames,
        currentTimeline: state.currentTimeline
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onScrollTo: (position) => dispatch({type: actionTypes.SCROLL_TO, payload: position}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))