/**
 * This function is used to create the media hashtable that is used to display 
 * metadata for each media when an user pastes a link or clicks on a media.
 * * The key is created using the entryID + '_' + mediaID and the value is the data for that media.
 * * However, if the media is an audio, then the key is just the entryID.
 * 
 * @param {Object} data - Data is the JSON data pulled from Drupal/ imported JSON files
 * @return {Object} Returns the media hashtable
 * 
 */
function createHashTable(data) {
    let medias = {}
    let key, newEntry
    //console.log("data:",data)
    let count=0;
    data.map((item, i) => {
        count++;
        item.Entries.map((entry) => {

            entry.Media.map((media) => {
                
                    key = entry.ID + '_' + media.ID
                    newEntry = medias
                    newEntry[key] = {
                        ...media, 
                        entryTitle: entry.Title, 
                        time: item.DateTitle, 
                        mediaType: media.Type + 's', 
                        description: entry.Description
                    }
                    
                    return {
                        ...medias,
                        medias: newEntry
                    }
               
            })
           
        })
        
    })
  
    return medias
}

export default createHashTable