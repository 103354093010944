import React, { Component } from 'react'
import checkURL from '../components/functions/checkURL'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actionTypes from '../store/actions'
import TimeTemp from '../components/functions/timeTemplate'
//import HorizontalScroller from 'react-horizontal-scroll-container';
import Downshift from 'downshift'   
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Footer from '../components/footer'
import Header from '../components/header'
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
/**
 * This is timelineStyles for timeline component version 2
 * The customized style that will be applied to material-ui components in the Timeline component
 * The style for the material-ui components been modified:
 * * Select
 * * Button
 * * Text
 */
const timelineStyles = () => ({
    Select: {
        background: 'linear-gradient(to bottom,rgb(138, 138, 138) 0%,rgb(56, 57, 57) 47%,rgb(35, 36, 36) 50%,rgb(35, 36, 36) 100%)',
        color: 'rgb(255, 255, 255)',
        width: '285px',
        padding: '0 5px',
        top: 0,
    },
    Button: {
        color: 'white',
        background: 'linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(54,54,54,1) 71%, rgba(107,107,107,1) 100%)',
        borderBottom: '1px solid #747474',
        borderTop: '1px solid #747474',
        borderRadius: '0',
        height: '35px',
        margin: '0',
        padding: '0 7px',
        '&:hover': {
            background: 'rgb(0, 170, 218)',
            cursor: 'pointer'
        }
    },
    Text: {
        width: '135px',
        color: 'white',
        border: '0.5px solid white',
        backgroundColor: 'white',
        borderRadius: '5px',
        marginTop: '3.5px',
        marginRight: '3.5px',
        height: '1.9em',
        marginLeft: '3px'
    }
    
});


/**
 * * This is the timeline component version 2
 * * Timeline is the parent component which contains the timelineTemp component
 * * It is the main component for all three timeline datas
 */
class Timeline extends Component {

    /**
     * Initialized the state and all of the member variables
     * and bind all of the traditional function declarations for event handlers to the current component
     * @param {Object} props - the data been passed from the Redux
     */
    constructor(props){
        super(props)
        
        /**
         * the state object to store all data to display on the page
         * * isPaused: the state that controls the functionality of auto-scrolling
         * * scrollPos: the state that performs auto-scrolling, it will be assigned to setInterval function and repeatedly calling scroller function
         * * currentTimeline: the state that stored the name of current timeline, which is getting from the url
         * * timelines: the state that stored all of the name of the timelines, it is an array
         * * openShareModal: the state that controls either displaying the share pop out window
         * * isLoading: the state that controls either displaying the loading icon when the data is still fetching or hidding it when the data is all in the timeline
         * state: @type {Object}
         */ 
        this.position = 'beginning'
        this.state = {  
            isPaused: true,
            scrollPos: null,
            currentTimeline: '',
            timelines: [],
            openShareModal: false,
            isLoading: true,
        }

         /**
         * * the member variable for Timeline component
         * * refList is the object that stores the reference name with the key-value pair
         * * the key is the entry-id
         * * value is the reference name
         * * the reference name is used when user clicks go back from media detail page, 
         * * then page will scroll to the section where user was on the timeline page 
         * @type {Object}
         */
        this.refList = {}

        /**
         * * the member variable for Timeline component
         * * the object variable that stores HorizontalScroller element on the DOM
         * @type {Object}
         */
        this.horizontalContainer = {}

        /**
         * * the member variable for Timeline component
         * * the boolean variable that stores either user's mouse is clicked
         * @type {boolean}
         */
        this.isDown = false

        this.isScrollOn = true

        /**
         * * the member variable for Timeline component
         * * the integer variable that stores current x-coordinate when the user's mouse is clicked
         * @type {number}
         */
        this.startX = 0

        /**
         * * scrollLeft is the member variable for Timeline component
         * * the integer variable that stores the number of px that horizontalContainer has been scrolled from the left edge
         * @type {number}
         */
        this.scrollLeft = 0

        /**
         * label is the member variable for Timeline component
         * <br>
         * each element is an object and it contains the following info:
         * * media type
         * * media key
         * * media name
         * @type {Object}
         */
        this.label = []

        this.scroller = this.scroller.bind(this)
        //this.scrollView = this.scrollView.bind(this)
        this.scrollOffset = this.scrollOffset.bind(this)
        this.handleShareModal = this.handleShareModal.bind(this)
        this.handleScrolling = this.handleScrolling.bind(this)
        this.handleMouseDown = this.handleMouseDown.bind(this)
        this.handleMouseUp = this.handleMouseUp.bind(this)
        this.handleMouseMove = this.handleMouseMove.bind(this)
        this.handleFullScreen = this.handleFullScreen.bind(this)
        this.handleEscFullScreen = this.handleEscFullScreen.bind(this)
        this.handleChangeTimeline = this.handleChangeTimeline.bind(this)
        this.renderTimeline = this.renderTimeline.bind(this)
    }
    
    /**
     * This is one of the react life cycle hook, it will be running after the render function, then DOM will be displaying the components
     * <br>
     * In this function: 
     * * fetching data by using axios through getTimeline function
     * * displaying the data on the timeline  by calling renderTimeline function
     * * getting the name of the current timeline and display it on the dropdown by calling getTimelineNames function
     * * starting the auto scrolling functionality by using the setInterval function, scroller function will be execute within 55 milliseconds
     * * adding the mousedown, mousemove and mouseup for the horizontal container, which allows the user to click the screen and drag either toward left or right
     * * adding exit full screen by using escape key event handler to the whole page
     * * adding the event handler to prevent user to use F11 key to enter and exit full screen
     */
    componentDidMount() {
        //console.log(this.props.hash)
        this.getTimelineNames()
        this.createRefList()
        this.setState({
            ...this.setState,
            scrollPos: setInterval(this.scroller, 55)
        })
        this.horizontalContainer = document.querySelector('.timeline-container')

        if(this.horizontalContainer){
            this.horizontalContainer.addEventListener('mousedown', this.handleMouseDown)
            document.addEventListener('mouseup', this.handleMouseUp)

        }

        document.addEventListener('webkitfullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('mozfullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('fullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('MSFullscreenChange', this.handleEscFullScreen, false)
        
        document.addEventListener("keydown", e => {
            if(e.key === "F11") e.preventDefault();
        });


        if (this.horizontalContainer.addEventListener) {
            // IE9, Chrome, Safari, Opera
            this.horizontalContainer.addEventListener("mousewheel", this.scrollHorizontally, false);
            // Firefox
            this.horizontalContainer.addEventListener("DOMMouseScroll", this.scrollHorizontally, false);
        }
        // IE 6/7/8
        else this.horizontalContainer.attachEvent("onmousewheel", this.scrollHorizontally);
    } 

    /**
     * * This is one of the react life cycle hook, it will be running before the component is destroyed
     * * In this function, I remove all of the event listeners that I added in the componentDidMount function
     */
    componentWillUnmount() {
        this.horizontalContainer = document.querySelector('.timeline-container')
        clearInterval(this.state.scrollPos);

        if(this.horizontalContainer){
            this.horizontalContainer.removeEventListener('mousedown', this.handleMouseDown)
            this.horizontalContainer.removeEventListener('mousemove', this.handleMouseMove)
            document.removeEventListener('mouseup', this.handleMouseUp)
        }

        this.horizontalContainer.addEventListener('scroll', this.handleScroll, true);

        if (window.addEventListener) {
            // IE9, Chrome, Safari, Opera
            this.horizontalContainer.addEventListener("mousewheel", this.scrollHorizontally, false);
            // Firefox
            this.horizontalContainer.addEventListener("DOMMouseScroll", this.scrollHorizontally, false);
        }
        // IE 6/7/8
        else this.horizontalContainer.attachEvent("onmousewheel", this.scrollHorizontally);

        document.removeEventListener('webkitfullscreenchange', this.handleEscFullScreen, false)
        document.removeEventListener('mozfullscreenchange', this.handleEscFullScreen, false)
        document.removeEventListener('fullscreenchange', this.handleEscFullScreen, false)
        document.removeEventListener('MSFullscreenChange', this.handleEscFullScreen, false)
    }
    
    /**
     * * This is one of the react life cycle hook, it will be running whenever there's an update on the DOM
     * * It will handle the scroll to the location of the media on the corresponding timeline 
     * after the user clicks go back from the media detail page
     * * It will handle the loading status
     * @param {Object} prevProps - prevProps is the data before before the componenet get updated
     */
    componentDidUpdate(prevProps) {
        //this.renderTimeline() 
        this.scrollOffset(this.props.position) 
        // handles going back using the browser go back button to correctly update currentTimeline
        if(prevProps.match.params.timelineID !== this.props.match.params.timelineID){
            this.setState({
                ...this.state,
                currentTimeline: this.props.timelineNames['timeline' + this.props.match.params.timelineID].name
            })
        }

        if(this.state.isLoading){
            this.setState({
                ...this.state,
                isLoading: false
            })
        }
        
        this.horizontalContainer = document.querySelector('.timeline-container')
    
        if(this.horizontalContainer){
            this.horizontalContainer.addEventListener('mousedown', this.handleMouseDown)
            document.addEventListener('mouseup', this.handleMouseUp)
        }

       
        if (this.horizontalContainer.addEventListener) {
            // IE9, Chrome, Safari, Opera
            this.horizontalContainer.addEventListener("mousewheel", this.scrollHorizontally, false);
            // Firefox
            this.horizontalContainer.addEventListener("DOMMouseScroll", this.scrollHorizontally, false);
        }
        // IE 6/7/8
        else window.attachEvent("onmousewheel", this.scrollHorizontally);
        document.addEventListener('webkitfullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('mozfullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('fullscreenchange', this.handleEscFullScreen, false)
        document.addEventListener('MSFullscreenChange', this.handleEscFullScreen, false)      
        
 

    }

    scrollOffset(entryID) {
        //console.log("Entry ID:",entryID,"Reflist:",this.refList)
        let position = 0 
        var x = window.matchMedia("(max-width: 700px)")
        if(entryID && entryID !== 'beginning' && this.refList[entryID] !== undefined && this.refList[entryID].current) {
            if (x.matches) {
                //Back to position on mobile device
                position = this.refList[entryID].current.offsetLeft + 250
            } else {
                //Back to position on desktop device
                position = this.refList[entryID].current.offsetLeft - 300
            }
            //Set interval to wait SimpleBar element loaded
          var checkExist = setInterval(function() {
            if (document.querySelector('.timeline-container').SimpleBar) {
                const container = document.querySelector('.timeline-container').SimpleBar.getScrollElement('x')
                //console.log(position)
                container.scrollLeft = position
                clearInterval(checkExist)
            }
         }, 100)
        }
    }

    scrollHorizontally(event) {
        //console.log('Wheel',event.detail)
        const container = document.querySelector('.timeline-container').SimpleBar.getScrollElement('x')
        if(event.wheelDelta < 0 || event.detail > 0){
            container.scrollLeft += 5
        }else{
            container.scrollLeft -= 5
        }
    }


    /**
     * This function is used to generate the data for the timeline dropdown list 
     */
    getTimelineNames() {
        let currentPath
        if(this.props.timelineNames['timeline' + this.props.match.params.timelineID]) {
            currentPath = this.props.timelineNames['timeline' + this.props.match.params.timelineID].name
        }
        // let currentPath = this.props.timelineNames['timeline' + this.props.match.params.timelineID].name 
        // currentPath = currentPath.split('_').join(' ')
        let timelineList = []
        // timelinesInfo.Response.Timelines.Timeline.map((item) => {
        //     timelineList.push(item["_Name"])
        //     return true
        // })

        Object.keys(this.props.timelineNames).map((data) => {
            timelineList.push(this.props.timelineNames[data].name)
            return true
        })

        this.setState({
            ...this.state,
            timelines: [...timelineList],
            currentTimeline: currentPath
        })

    }

    /**
     * This function is used to create the reference name for each section on the timeline page,
     * which allows React to scroll to the position of the media when the user clicks go back from
     * the media detail page
     */
    createRefList() {
        let currentTimeline = this.props.timeline['timeline' + this.props.match.params.timelineID]

        currentTimeline.map((data) => {
            data.Entries.map((entry) => {
                this.refList[entry.ID] = React.createRef()
            })
        })

    }



    /**
     * renderTimeline is used to generate each section of the timeline by
     *  mapping the timeline data and passing it to the timeline template
     *  component; moreover, it calls createRefList()
     * @returns {Object} render
     */
    renderTimeline() {
        //this.createRefList()
        
        let current = 'timeline' + this.props.match.params.timelineID
        let timelineTemp = this.props.timeline[current].map((data, i) => {
            return (
                < TimeTemp 
                    key={i} 
                    currentHash={this.props.hash[current]}
                    title={data.Title}
                    body={data.Description}
                    time={data.DateTitle}
                    entries={data}
                    match={this.props.match}
                    history={this.props.history}
                    refList={this.refList}
                    onScrollTo={this.props.onScrollTo}
                    pauseScroll={() => this.setState({...this.state, isPaused: true})}
                />
            )
        })

        let render = (
            <div className="timeline-content">
            <div style={{ height: '600px', background:'transparent', width:'100%'  }}
                        data-simplebar
                        data-simplebar-auto-hide="false"
                        data-simplebar-force-visible
                        className={'timeline-container slider'}
                        >
                <ul className='slides' id = "makeMeScrollable">{timelineTemp}</ul>
            </div>
            </div>

        )
        return render
    }


    /**
     * * This function is the event handler for auto-scroll feature
     * * When the isPaused is false, then the auto-scroll feature will be executed
     */
    scroller(){
        if(!this.state.isPaused){
            this.startScroll()
        } else {
            clearInterval(this.state.scrollPos) 
        }
    }

    /**
     * This is function is performing the auto-scrolling, as long as the state of isPaused is false,
     * the timeline container will be scroll toward right
     */
    startScroll() {
        // container is the HorizontalScroller component's classname
        //const container = document.getElementsByClassName("container")
      
        const container = document.querySelector('.timeline-container').SimpleBar.getScrollElement('x')
        if(container){
            container.scrollLeft += 2.5
        }else{
            this.setState({
                ...this.state,
                isPaused: true
            }) 
        } 
    }

    /**
     * handleShareModal handles whether or not the share modal should be shown
     */
    handleShareModal = () => {
        this.setState({
            ...this.state,
            openShareModal: !this.state.openShareModal
        })
    }
    
    /**
     * This is event handler to check if the user clicks the pause button on the page
     * * If the users click the pause button, then the scrollPos state will be clear
     * * Otherwise, the scrollPos state will be execute scroller function within 55 milliseconds
     */
    handleScrolling() {
        this.props.onScrollTo(undefined)
        this.setState({
            isPaused: !this.state.isPaused,
            scrollPos: setInterval(this.scroller, 55)
            
        })
        if(!this.state.isPaused){
            clearInterval(this.state.scrollPos)
        }
    }


    /**
     * This function is the event handler when the users click the mouse
     * * It will store the position where the users click the mouse on the page to the member variable startX
     * * It will also store the timeline container's scrollLeft value to the member variable scrollLeft 
     */
    handleMouseDown(e) {
        this.isDown = true
        e.preventDefault()
        this.horizontalContainer.addEventListener('mousemove', this.handleMouseMove);
        const container = document.querySelector('.timeline-container').SimpleBar.getScrollElement('x')
        this.startX = e.pageX - container.offsetLeft
        this.scrollLeft = container.scrollLeft
        //console.log("Mouse Down Start X",this.startX )
    }

    /**
     * This function is the event handler when the users relase the mouse
     */
    handleMouseUp() {
        this.isDown = false
        this.horizontalContainer.removeEventListener('mousemove', this.handleMouseMove);
    }

    /**
     * This function is the event handler when the users move the mouse
     * * In this function, it performs the click and drag
     * * When the users clicked and move horizontally, then the distance is calculated
     */
    handleMouseMove(e) {
        if(!this.isDown) return
        e.preventDefault()
        const container = document.querySelector('.timeline-container').SimpleBar.getScrollElement('x')
        if(container){
            const x = e.pageX - container.offsetLeft
            const walk = (x - this.startX) * 3
            container.scrollLeft = this.scrollLeft - walk
        }
    }
      

    /**
     * This is the event handler for enter and exit the full screen mode by pressing the button on the page
     */
    handleFullScreen(e){
        this.props.onScrollTo(undefined)
        e.preventDefault()
        const fullScreenDiv = document.querySelector('html')
        if(this.props.isFullScreen){
            if(document.exitFullscreen){
                document.exitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        }else{
            if (fullScreenDiv.requestFullscreen) {
                fullScreenDiv.requestFullscreen()
            } else if (fullScreenDiv.mozRequestFullScreen) { /* Firefox */
                fullScreenDiv.mozRequestFullScreen()
            } else if (fullScreenDiv.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                fullScreenDiv.webkitRequestFullscreen()
            } else if (fullScreenDiv.msRequestFullscreen) { /* IE/Edge */
                fullScreenDiv.msRequestFullscreen()
            }
        }
        this.props.onFullScreen(!this.props.isFullScreen)
    }

    /**
     * This is the event handler for users to use esc key to exit fullscreen mode
     */
    handleEscFullScreen(){
        this.props.onScrollTo(undefined)
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            this.props.onFullScreen(false)
        }else{
            this.props.onFullScreen(true)
        }
    }

    /**
     * This function is used to create an array of search term labels from the entry title of the timeline
     * @return {Object} an array of search term and assigned to member variable: label
     */
    searchTermLabelMaker() {
        let localLabel = []
        let prevMediaKey
        let currentHash = this.props.hash['timeline' + this.props.match.params.timelineID]

        Object.keys(currentHash).map((item) => {
            let currentKey = String(item).substr(0, item.indexOf("_"))
            let mediaId = String(item).substr(item.indexOf("_") + 1)
            
            // if(currentHash[item].mediaType === 'audios'){
            //     currentKey = item
            //     mediaId = ''
            // }
            
            if(prevMediaKey) {
                if(currentHash[item].entryTitle !== currentHash[prevMediaKey].entryTitle || currentHash[item].mediaType !== currentHash[prevMediaKey].mediaType){
                    localLabel.push({
                        key: currentKey,
                        mediaKey: mediaId,
                        mediaType: currentHash[item].mediaType,
                        label: currentHash[item].entryTitle
                    })
                }
            } else {
                localLabel.push({
                    key: currentKey,
                    mediaKey: mediaId,
                    mediaType: currentHash[item].mediaType,
                    label: currentHash[item].entryTitle
                })
            }
            prevMediaKey = item
            return true
        })

        this.label = [...localLabel]
    }

    /**
     * This function is the event handler for users to swtich between timelines through the dropdown next to the logo image
     */
    handleChangeTimeline(event) {
        this.props.onScrollTo('beginning') 
        const container = document.querySelector('.timeline-container').SimpleBar.getScrollElement('x')
        container.scrollLeft = 0

        this.setState({
            ...this.state,
            currentTimeline: event.target.value,
            isLoading: true
        }, () => {
            Object.keys(this.props.timelineNames).map((data) => {
                if(this.props.timelineNames[data].name.toLowerCase() === this.state.currentTimeline.toLowerCase()) {
                    this.props.history.push(`${this.props.timelineNames[data].tid}`)
                    //this.renderTimeline()
                }
            })
        })
    }

    /**
     * * The entry point for this component
     * * Everything that's displaying on the DOM is from this function
     * * Whenever there's a change made to the DOM, this function will be executed
     * @return {Object} Return an JSX Object.
     */
    render() {
        
        let validURL = checkURL(this.props.match.params, this.props.timeline, this.props.hash)
        if(validURL) {
            let returnVal = this.renderTimeline()
            if(this.props.timeline.length === 0) {
                this.getTimeline()
            }

            const search = (
                <Downshift
                    onChange={selection => {
                        if(selection){
                            // remove lines 437, 440, 441 - 444 when audios is using mediaID
                            if(selection.mediaKey) {
                                // for images & videos
                                this.props.history.push(`/timeline/${this.props.match.params.timelineID}/${selection.mediaType}/${selection.key}/${selection.mediaKey}`)
                            }
                            else {
                                // for audios
                                this.props.history.push(`/timeline/${this.props.match.params.timelineID}/${selection.mediaType}/${selection.key}`)
                            }
                        } 
                    }}
                    itemToString={item => (item ? item.label : "")}
                >
                    {({ 
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        isOpen,
                        inputValue,
                        selectedItem,
                        highlightedIndex
                        }) => (
                            <div>
                                <TextField className={classes.Text} {...getInputProps()}
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                        <InputAdornment>         
                                            <SearchIcon /> 
                                        </InputAdornment>
                                        )
                                    }}
                                    inputProps={{
                                        'aria-label': 'Search Media Items'
                                    }}
                                />      
                    
                                {/* <ul {...getMenuProps()} className="select" > */}
                                <ul className="select" >
                                    <div style={isOpen ? { height: "110px", overflowX: "hidden", overflowY: "auto", width: '176px'} : {display:'none'}}>
                                        { isOpen 
                                            ?   
                                                this.label.filter(item => !inputValue.toLowerCase() || (item.label ? item.label : "").toLowerCase().includes(inputValue.toLowerCase()))
                                                .map((item, index) => {
                                                    let mediaIcon
                                                    //console.log('this is item in search: ', item)
                                                    if(item.mediaType === 'images'){
                                                        mediaIcon = <i className="fas fa-camera"></i>
                                                    }else if(item.mediaType === 'audios'){
                                                        mediaIcon = <i className="fas fa-volume-up"></i>
                                                    }else{
                                                        mediaIcon = <i className="fas fa-video"></i>
                                                    }
                                                    
                                                    return(
                                                        <li
                                                            {...getItemProps({
                                                                key: item.label+index,
                                                                item,
                                                                index,
                                                                isactive: highlightedIndex === index ? 'true' : 'false',
                                                                isselected: selectedItem === item ? 'true' : 'false',
                                                            })}
                                                            className='searchResults'
                                                            aria-label={item.label}
                                                        >   
                                                        {mediaIcon}<p className="result-title">{item.label.substr(0,49)}</p>...
                                                        </li>
                                                    )
                                                })
                                            : null
                                        }
                                    </div>
                                </ul>
                            </div>
                        )}
                </Downshift>
            )

            this.searchTermLabelMaker()
            const { classes } = this.props;
            return (
                <div style={this.state.isLoading ? {display: 'none'} : {}}>
                    <Header
                        params={this.props.match.params}
                        search={search}
                        currentTimeline={this.state.currentTimeline}
                        timelines={this.state.timelines}
                        handleChangeTimeline={this.handleChangeTimeline}
                        isPaused={this.state.isPaused}
                        handleScrolling={this.handleScrolling}
                        isFullScreen={this.props.isFullScreen}
                        handleFullScreen={this.handleFullScreen}
                        openShare={this.state.openShareModal} 
                        handleShare={this.handleShareModal}
                    />
                    {returnVal}
                    < Footer history={this.props.history} location={this.props.location}/>
                </div>
            )
        } else {
            return < Redirect to='/pagenotfound' push={true} />
        }
    }
}

/**
 * This function maps all of the Redux states to this component so that you can
 * access the Redux state in this component's props. For example, to access the Redux timeline 
 * state, you'd use this.props.timeline.
 * 
 * @param {Object} state - the state variables stored in the Redux
 * @returns {Object} Returns the Redux states in a form of props. 
 */
const mapStateToProps = state => {
    return {
        timeline: state.timeline,
        hash: state.hash,
        position: state.position,
        isFullScreen: state.isFullScreen,
        timelineNames: state.timelineNames,
        ref: state.ref
    }
}

/**
 * This function maps all of the Redux methods to this component. They can be accessed in
 * this.props like the Redux states.
 * 
 * @returns {Object} Returns the Redux state handlers in your props.
 */
const mapDispatchToProps = dispatch => {
    return {
        onScrollTo: (position) => dispatch({type: actionTypes.SCROLL_TO, payload: position}),
        onFullScreen: (isFullScreen) => dispatch({type: actionTypes.GET_ISFULLSCREEN, payload: isFullScreen}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(timelineStyles)(Timeline))