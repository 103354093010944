import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import ReactHtmlParser from 'react-html-parser';
import "@fortawesome/fontawesome-free/css/all.css";
import '../../css/timelineTemp.css'

/**
 * * This is timeTemp component version 2
 * * TimeTemp is the component that will display each event on the timeline
 * * It contains the description of the event and medias
 */
class TimeTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            textModal: false
        }
        this.handleTextModal = this.handleTextModal.bind(this)
    }
/**
 * This function is used to handle opening and closing the text modal on the Timeline page
 */
    handleTextModal() {
        this.setState({
            ...this.state,
            textModal: !this.state.textModal
        }, () => {
            if(this.state.textModal) {
                this.props.pauseScroll()
            }
        })
        this.props.onScrollTo(undefined)
    }




/**
 * This function handles displaying the individual media of each section in the timeline.
 * Does not take in any params, but instead uses the data is passed back from the timeline.js.
 * It first handles all media with length 1. This means that it is either an audio or video or just one image.
 * If the length is greater than one, then it means that more than one image is being displayed for that entry, this is handled in the
 * else.
 * 
 * @returns Returns an JSX Object.
 */
    displayMedia = (data) => {
                if(data.Media.length === 1) {
                    return (
                        data.Media.map((media, i) => {
                            if(media.Type === 'audio') {
                                // format for audio
                                let readMore = null

                                if((data.Title).substring(80).length !== 0) {
                                    readMore = (<span>...</span>)
                                }

                                return (
                                    <div ref={this.props.refList[data.ID]} className='audios entry audio' key={media}>
                                
                                        <Link aria-label={media.Title} to={`/timeline/${this.props.match.params.timelineID}/${'audios'}/${data.ID}/${media.ID}`} className='title' >
                                           {media.Title}      
                                        </Link>
                                        <p className='subtitle'> <i className="fas fa-volume-up"></i> {data.Title.substring(0, 80)}{readMore}</p>
                                    </div>
                                )
                            } else if(media.Type === 'video') {
                                //console.log(media.Title)
                                // format for video
                                return (
                                    <div ref={this.props.refList[data.ID]} key={media.ID} className='video entry'>
                                        <Link aria-label={media.Title} to={`/timeline/${this.props.match.params.timelineID}/${'videos'}/${data.ID}/${media.ID}`} >
                                            <span className='playButton'><i className="far fa-play-circle"></i></span>
                                            <img className="image-thumbnail"
                                                src={media.Thumbnail}
                                                alt={media.Title ? media.Title:"video"}
                                            >
                                            </img>
                                        </Link>
                                        <p className='subtitle'><i className="fas fa-video"></i> {data.Title}</p>
                                    </div>
                                )
                            } else if(media.Type === 'image' && media.Show === 1) {
                                // format for image
                                console.log("Media:",media)
                                console.log("Media.show:",media.Show)
                                
                                return (
                                    <div key={media.ID} className='image-thumbnail entry'>
                                        <Link aria-label={media.Alt} key={media.ID} to={`/timeline/${this.props.match.params.timelineID}/${'images'}/${data.ID}/${media.ID}`} >
                                            <img 
                                                src={media.Thumbnail} 
                                                alt={media.Alt}
                                                ref={this.props.refList[data.ID]}
                                                className={media.Show === 1 ? '' : 'dont-show'}
                                                
                                            />
                                        </Link>
                                        <p className='subtitle'><i className="fas fa-camera"></i> {data.Title}</p>
                                    </div>
                                )
                               
                            }
                            
                        })
                    )
                } else { 
                    // if more than 1 image is being displayed per entry
                    
                    return(
                        <div className='image-thumbnail entry' key={data.ID} ref={this.props.refList[data.ID]}>
                            <div>
                                {
                                    data.Media.map((media, i) => {
                                        if(media.Show === 1) {
                                            return (
                                                <Link aria-label={media.Alt} key={media.ID} to={`/timeline/${this.props.match.params.timelineID}/${'images'}/${data.ID}/${media.ID}`} >
                                                    <img 
                                                        src={media.Thumbnail} 
                                                        //alt={'image' + i}
                                                        alt={media.Alt} 
                                                        className={media.Show === 1 ? '' : 'dont-show'}
                                                    />
                                                </Link>
                                            )
                                        }
                                    })
                                }
                            </div>
                             <p className='subtitle'><i className="fas fa-camera"></i> {data.Title}</p>
                        </div>
                    )
                }
    }

    
    renderMedia = () => {
        return (
            <div className='bottom'>
                <div className="upper">
                {this.props.entries.Entries.map((data,i) => {
                    if(i%2===0){
                        return(
                            this.displayMedia(data)
                        )
                    }
                })}
                </div>
                <div className="lower">
                {this.props.entries.Entries.map((data,i) => {
                    if(i%2===1){
                        return(
                            this.displayMedia(data)
                        )
                    }
                })}
                </div>
            </div>
        )
    }

    /**
     * For the timeline template, each section of the timeline is broken up into two parts: Header and Section.
     * Header consists of the event time, event title, and event description.
     * Section contains the media, if there are no media, then it shows the description.
     */
    render() {
        let headerText, body, readMore, descr, descr_trim
        descr = this.props.body
        descr_trim = descr.substring(0, 225)
        descr_trim = descr_trim.substring(0, Math.min(descr_trim.length, descr_trim.lastIndexOf(" ")))
        if(this.props.entries.Entries.length === 0) {
            headerText = null
            body = (<div className='bottom'><div className='bodyText'>{ReactHtmlParser(descr)}</div></div>) 
        } else {
            if(ReactHtmlParser(descr.substring(155)).length !== 0) {
                readMore = (<p>... <span onClick={this.handleTextModal} className='show-more'>more</span></p>)
            }

            headerText = (
                <div className='description'>
                    { ReactHtmlParser(descr_trim) }  
                    {readMore}
                    <Modal 
                        open={this.state.textModal}
                        onClose={this.handleTextModal}
                        disableEscapeKeyDown={true}
                        disableBackdropClick={true}
                        className="modal-container details-modal details-description"
                    >
                        <div className='textModal'>
                            <header className="line">
                                <span id="closeModal" class="close-modal" onClick={this.handleTextModal}></span>
                                <span className="time"></span>
                            </header>
                            <div >
                                <p className="date">{this.props.time}</p>
                                <p className="title">{this.props.title}</p>
                                <p className='description'>{ ReactHtmlParser(descr) }</p>
                            </div>
                        </div>
                    </Modal>
                </div>
            )

            body = this.renderMedia()
        }

        return(
            <li className="timeline-box wrapper">
            <article>
                <div className='top'>
                    <div className="additionalBorder"></div>
                    <h1>{this.props.time}</h1>
                    <h2 className="header">{this.props.title}</h2>
                    {headerText}
                </div>
                {body}
            </article>
            </li>
        )
    }
}

export default TimeTemplate;