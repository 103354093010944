import React, { Component } from 'react'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, EmailIcon, EmailShareButton } from 'react-share';
import '../css/share.css'

/**
 * The customized style that will be applied to material-ui component on the share component
 * The style of the material-ui components been modified:
 * * Button
 */
const shareStyles = {
    Button: {
        color: 'white',
        border: '0.5px solid white',
        height: '25px',
        margin: '0',
        padding: '0',
        '&:hover': {
            background: 'rgb(0, 170, 218)',
            cursor: 'pointer'
        },
        '&:focus': {
          outline: '2px solid #8cc63f'
        }
    }
}

/**
 * ShareModal is been used in the timeline component and in mediaDetail component
 * ShareModal is the component that allows the users to:
 * * share the current url in facebook, twitter, email
 * * copy the current url and paste it into a new tab
 */
class ShareModal extends Component {

    /**
     * @param {*} props - the style been passed from the shareStyles component defined on the top
     * and the data been passed from the timeline component & mediaDetail component
     * Initialized the state property for the ShareModal
     */
    constructor(props) {
        super(props)

        /**
         * The state object for the ShareMoal
         * * copied: a boolean variable that checks either the link showed in the ShareModal is copied or not
         */
        this.state = {
            copied: false
        }
    }

    handleKeyPressed(e) {
        console.log("event: ",e)
        // if (e.k == 'Enter') {
        // //   this.handleShare();
        // //   this.setState({...this.state, copied: false})

        // }
      }

    /**
     * The entry point for the ShareModal,
     * it will display the modal that contains:
     * * the current url in a text field
     * * share the url in email, twitter, facebook
     * * a dynamic button(default is copy)
     * once the users click it, then it change to copied
     * @return {Object} Return an JSX Object.
     */
    render() {
        const classes = this.props.classes
        let copy = (
            <CopyToClipboard text={window.location.href}
                onCopy={() => this.setState({...this.state, copied: true})}>
                <Button className={classes.Button}>{this.state.copied ? 'Copied!' : 'Copy'}</Button>
            </CopyToClipboard>
        )
        return (
            <Modal 
                open={this.props.share}
                onClose={this.props.handleShare}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                style={{top: '40%'}}
                className='shareModal'
            >
                <div className='shareItems'>
                    <div>
                    <button
                        tabIndex={0}
                        title="Close Button"
                        className="fas fa-times closeButton" 
                        onClick={() => {
                            this.props.handleShare()
                            this.setState({...this.state, copied: false})
                        }}
                        
                    >
                    </button>
                        <span style={{paddingRight: '7px'}}>Share Url:</span>
                        <input
                        tabIndex={-1}
                            type='url'
                            value={window.location.href}
                            readOnly
                            className='shareUrl'
                        />
                        {copy}
                    </div>
                     <ul>
                <li class="shMail">
                    <a class="mail standardButton" href="mailto:?subject=Explore the 9/11 interactive timelines&amp;body=Learn about the attacks of September 11, 2001 and the events that unfolded through images, audio and video.  https%3A%2F%2Ftimeline.911memorial.org%2F%23Timeline%2F2"><span></span></a>
                </li>
                <li class="shFacebook">
                    <a class="facebook standardButton" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftimeline.911memorial.org%2F%23Timeline%2F2"><span></span></a>
                </li>
                <li class="shTwitter">
                    <a class="twitter standardButton" target="_blank" href="https://twitter.com/intent/tweet?source=webclient&amp;text=https%3A%2F%2Ftimeline.911memorial.org%2F%23Timeline%2F2"><span></span></a>
                </li>
            </ul>
                </div>
            </Modal>
        )
    }
}

export default (withStyles(shareStyles)(ShareModal))