import * as actionTypes from './actions'

/**
 * initialState contains all the Redux states and their initial values.
 * * timeline and hash are updated only once and during theloading of the website.
 * * position is updated everytime the user clicks go back from the media detail page.
 * * isFullScreen is updated everytime the user enters and exits fullscreen.
 * * timelineLabels contains all of the timeline names
 */
const initialState = {
    timeline: {},
    hash: {},
    position: '',
    isFullScreen: false,
    timelineNames: {}
}

/**
 * The reducer handles state changes. Have to make sure that the actionTypes match in both reducer and the component.
 * To ensure this, use action.js to store all of your action types and then import it to both your component file and
 * reducer.js.
 * 
 * @param {Object} state - initialState
 * @param {Object} action - contains all of the params that are being passed to Redux from the component 
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TIMELINE:
            return {
                ...state,
                timeline: {
                    ...state.timeline,
                    [action.name]: action.payload
                }
            }
        case actionTypes.CREATE_HASH:
            return {
                ...state,
                hash: {
                    ...state.hash,
                    [action.name]: action.payload
                }
            }
        case actionTypes.SCROLL_TO:
            return {
                ...state,
                position: action.payload
            }
        case actionTypes.GET_ISFULLSCREEN:
            return {
                ...state,
                isFullScreen: action.payload

            }
        case actionTypes.GET_TIMELINE_NAMES:
            return {
                ...state,
                timelineNames: {
                    ...state.timelineNames,
                    [action.name]: action.payload
                }
            }
        default: 
    }
    return state;
};

export default reducer;