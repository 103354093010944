import React from 'react'
import { Link } from 'react-router-dom'
import '../css/footer.css'
import { fade, makeStyles } from "@material-ui/core/styles";
import {IconButton, Button, Select} from "@material-ui/core";
import classNames from 'classnames';
/**
 * This compoenent is used to create the footer that consists of the link to the 
 * about page and donation page.
 * 
 * @param {Object} props - used to access the history
 * @return {Object} Return an JSX Object.
 */
const Footer = (props) => {
    const useStyles = makeStyles(theme => ({
        Button: {
            color: 'white',
            /*border: '0.8px solid white',*/
            borderRadius: '0.5px',
            height: '20px',
            width: '45px',
            margin: '0',
            padding: '0 7px',
            fontSize: '1.0em',
            textTransform: 'none',
            '&:hover': {
                cursor: 'pointer'
            },
            '&:focus': {
                outline: '2px solid #8cc63f'
            }
        }  
       
      }));
      
    const classes = useStyles();
    const back = (
        <Button className={classes.Button}
        /*<span*/ 
            onClick={() => {
                // if user is on the about page, change text to back and navigation to go back
                // else, navigate user to about page
                if(props.location.pathname.includes('about')) {
                    if(props.history.length > 2) {
                        props.history.goBack()
                    } else {
                        props.history.push('/')
                    }
                }
            }}
        >
            Back
        </Button>
        /*</span>*/
        
    )

    const about = (
        <span>
            <Link 
                to='/about'
            >
                About
            </Link>
        </span>
    )

    return (
        <div id='footer'>
            <span id='footer-link'>
                {props.location.pathname.includes('about') ? back : about} {/* Depending on where the user is, the text changes */}
            </span>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span><a href={'https://www.911memorial.org/make-monetary-donation-now'} target='_blank' rel="noopener noreferrer">Donate</a></span>
        </div>
    )
}

export default (Footer)