import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles';
import '../css/error.css'


/**
 * The customized style that will be applied to material-ui component on the error component
 * The style of the material-ui components been modified:
 * * Button
 */
const errorStyle = () => ({
    Button: {
        color: 'white',
        background: 'linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(54,54,54,1) 71%, rgba(107,107,107,1) 100%)',
        border: '1px solid #747474',
        borderRadius: '3px',
        height: '35px',
        width: '90px',
        margin: '0',
        padding: '0 7px',
        marginTop: '35px',
        '&:hover': {
            background: 'rgb(0, 170, 218)',
            cursor: 'pointer'
        }
    }
});

/**
 * This component is used to render the error page.
 * 
 * @param {Object} props - to access the styling for material ui components
 * @return {Object} Return an JSX Object.
 */
const ErrorPage = (props) => {
    const classes = props.classes
    return (
        <div className='errorPage'>
            <h2 id='errorMes'>Sorry We Cannot Find What You Are Looking For</h2>
            <Link to=''><Button className={classes.Button}>Home</Button></Link>
        </div>
    )
}

export default (withStyles(errorStyle)(ErrorPage))