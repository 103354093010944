import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux'
import * as actionTypes from './store/actions'
import axios from 'axios'
import Home from './containers/home';
import About from './components/about'
import Loader from './components/loader'
import ErrorPage from './components/error'
import timelineLabels from './data/json_label.json'
// Use the following imports if working with the new JSON data
import timline1 from './data/timeline1.json'
import timline2 from './data/timeline2.json'
import timline3 from './data/timeline3.json'
import createHashTable from './components/functions/createHashTable'
import Timeline from './containers/timeline'
import MediaDetail from './components/functions/mediaDetail'
// Up to here
import './App.css';

/**
 * getting the data from the server and stored in the Redux
 */
class App extends Component {
  constructor(props) {
    super(props) 
    this.state = {
      timelineData: [],
      totalTimeline: 0
    }
  }
  componentDidMount() {
    //console.log("Data Source (drupal or local) :",this.props.source)
    if (this.props.source === 'drupal') {
        axios.get(`https://911memorial.org/labels`)
            .then((res) => {
                let timelineData = [];
                
                res.data.forEach((data) => {
                    timelineData.push(data);
                    this.props.onGetTimelineNames('timeline' + data.tid, data);
                });
                
                this.setState({
                    ...this.state,
                    timelines: timelineData,
                    totalTimeline: res.data.length
                });

                this.fetchTimelines(res.data);
            })
            .catch((err) => {
                console.log("API call failed, reading from local JSON:", err);
                this.readFromLocalJSON();
            });
    } else {
        console.log("Reading from local JSON.");
        this.readFromLocalJSON();
    }
  }

  fetchTimelines(timelines) {
    timelines.forEach((timeline) => {
        axios.get(`https://911memorial.org/timeline/${timeline.tid}`)
            .then((res) => {
                let timelineName = 'timeline' + timeline.tid;
                let hash = createHashTable(res.data);
                this.props.onCreateHash(timelineName, hash);
                this.props.onGetTimeline(timelineName, res.data);
            })
            .catch((err) => {
                console.log("Failed to fetch timeline:", err);
            });
    });
  }

  readFromLocalJSON() {
    let labels = timelineLabels;
    let timelineData = [];

    labels.forEach((data) => {
        timelineData.push(data);
        this.props.onGetTimelineNames('timeline' + data.tid, data);
    });

    this.setState({
        ...this.state,
        timelines: timelineData,
        totalTimeline: labels.length
    });

    let timelines = [timline1, timline2, timline3];
    timelines.forEach((timeline, index) => {
        let timelineHash = createHashTable(timeline);
        this.props.onCreateHash('timeline' + (index + 1), timelineHash);
        this.props.onGetTimeline('timeline' + (index + 1), timeline);
    });
  }



  /**
   * entry point for the entire project
   * @return {Object} a JSX object for the router
   */
  render() {
    //if(Object.keys(this.props.timeline).length === 1) {
    if(Object.keys(this.props.timeline).length > 0 && Object.keys(this.props.timeline).length === this.state.totalTimeline) {
      return (
        <Router>
          <div id="App">
            <Switch>
              <Route path="/pagenotfound" component={ ErrorPage } />
              <Route path="/" exact component={ Home } />
              <Route path='/about' exact component={About} />
              <Route path="/timeline/:timelineID" exact component={Timeline} />
              {/* <Route path="/timeline/:timelineID/:mediaType/:entryid" exact component={MediaDetail} /> */}
              <Route path="/timeline/:timelineID/:mediaType/:entryid/:mediaid" exact component={MediaDetail} />
              <Route path="*" component={ErrorPage} />
            </Switch>
          </div>
        </Router>
      )
    } else {
      return < Loader />
    }
  }
}

/**
 * This function maps all of the Redux states to this component so that you can
 * access the Redux state in this component's props. For example, to access the Redux hash(the hash table for the current timeline)
 * state, you'd use this.props.hash
 * 
 * @param {Object} state
 * @returns {Object} Returns the Redux states in a form of props. 
 */
const mapStateToProps = state => {
  return {
      timeline: state.timeline,
      hash: state.hash,
      timelineNames: state.timelineNames
  }
}

/**
 * This function maps all of the Redux methods to this component. They can be accessed in
 * this.props like the Redux states.
 * 
 * @returns {Object} Returns the Redux state handlers in your props.
 */
const mapDispatchToProps = dispatch => {
  return {
      onGetTimeline: (timelineName, timeline) => dispatch({type: actionTypes.GET_TIMELINE, name: timelineName, payload: timeline}),
      onCreateHash: (hashName, hash) => dispatch({type: actionTypes.CREATE_HASH, name: hashName, payload: hash}),
      onGetTimelineNames: (timelineName, timelineData) => dispatch({type: actionTypes.GET_TIMELINE_NAMES, name: timelineName, payload: timelineData})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);