import React from 'react'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Footer from './footer'
import logo from '../data/911_m&m_white.png'
import '../css/about.css'

/**
 * The customized style that will be applied to material-ui component on the about component
 * The style of the material-ui components been modified:
 * * Button
 */
const aboutStyle = () => ({
    Button: {
        color: 'white',
        border: '0.8px solid white',
        borderRadius: '3px',
        height: '45px',
        width: '90px',
        margin: '10px',
        padding: '0 7px',
        marginLeft: '3%',
        '&:hover': {
            background: 'rgba(0, 135, 255, 0.75)',
            cursor: 'pointer'
        }
    }
})

/**
 * This function handles going back from the about page. The props.history.length > 2
 * tells us that the user entered the about page from the timeline page, so we have to return to the timeline page.
 * Else, go to the home page.
 * 
 * @param {Object} props - needed to access the history prop
 * @return {Object} Return an JSX Object.
 */
const handleGoBack = (props) => {
    if(props.history.length > 2) {
        props.history.goBack()
    } else {
        props.history.push('/')
    }
}

/**
 * This component is used to display the about page.
 * @param {Object} props - used to access the material UI custom styling
 * @return {Object} a JSX object that be displaying the about page
 */
const about = (props) => {
    const { classes } = props
    let openAbout = false
    
    // check if the user is on the about page
    if(props.location.pathname.includes('about')) {
       openAbout = true
    }

    return (
        <div>
            <Modal
                open={openAbout}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                id='aboutModal'
            >
                <div id='about-container'>
                    <div 
                        className='aboutText'
                        onClick={() => {
                            handleGoBack(props)
                        }}
                    >
                        <h2>About the 9/11 Interactive Timeline</h2>
                        <p>The 9/11 Interactive Timeline was designed and engineered by the National September 11 Memorial & Museum.</p>
                        <img id='aboutLogo' src={logo} alt='National September 11 Memorial & Museum Logo'/>
                        <p><i>Warning: The timeline contains graphic images and sensitive content due to the nature of events related to the September 11, 2001 attacks.</i></p>
                        <p>Please help support our continued efforts of chronicling the history of September 11, 2001 through initiatives such as these educational interactive timelines.</p>
                        <a href={'https://www.911memorial.org/make-monetary-donation-now'} target='_blank' rel="noopener noreferrer"><Button tabIndex={-1} className={classes.Button}>Donate</Button></a>
                    </div>
                    <div id='footer'>
                        <Footer history={props.history} location={props.location}/>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default (withStyles(aboutStyle)(about))