import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import {Link} from 'react-router-dom'
import AppBar from "@material-ui/core/AppBar";
import {IconButton, Button, Select, MenuItem} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
//import AccountCircle from "@material-ui/icons/AccountCircle";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import desktopLogo from '../data/911_Logo.png'
import Share from './share'
import classNames from 'classnames';
import '../css/header.css'
import color from "@material-ui/core/colors/amber";
import '../App.css';
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    background: "transparent",
    display: "inline-block",
    float: "left",
    margin: "10px 0 0 -600px"
  },
  listItem: {
    cursor: 'pointer',
    width:'auto',
    display: "block",
    color: "#fff",
    outline: 'none',
    "&:hover": {
      color: "rgba(0, 135, 255, 0.75)",
    },
    '&:focus':{
      outline: '2px solid #8cc63f'
    },
  },
  
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  
  icon: {
    color: 'white',
    '&:focus':{
      outline: '2px solid #8cc63f'
    }
  },

  logo: {
    float:"left",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    },
    float:"left",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    float: "right"
  },
   list: {
    width: "100%",
    height: '100%',
    background: 'linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(54,54,54,1) 71%, rgba(107,107,107,1) 100%)',
    
  },
  iconButton:{
    '&:focus': {
      outline: '2px solid #8cc63f'
    } ,
  },
  
  Select: {
        background: 'linear-gradient(to bottom,rgb(138, 138, 138) 0%,rgb(56, 57, 57) 47%,rgb(35, 36, 36) 50%,rgb(35, 36, 36) 100%)',
        color: 'rgb(255, 255, 255)',
        //width: '285px',
        width: '265px',
        height: '34px',
        padding: '0 5px',
        top: 0,
        paddingLeft: '16px',
        verticalAlign: 'middle',
        borderTopRightRadius: '5px',
        borderBottomRightRadius:'5px',
        outline: 'none',
        lineHeight: '2',
        textAlign:'left',

        '&:hover': {
          background: 'linear-gradient(to bottom,#17a4d5 0%,#004d7c 100%)'
        } ,
        '&:focus': {
          outline: '2px solid #8cc63f'
        } ,
    },
    
    
    Button: {
        color: 'white',
        background: 'linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(54,54,54,1) 71%, rgba(107,107,107,1) 100%)',
        //borderBottom: '1px solid #747474',
        borderLeft: '1px solid #747474',
        borderRadius: '0',
        height: '30px',
        padding: '0 7px',
        '&:hover': {
            background: 'rgb(0, 170, 218)',
            cursor: 'pointer'
        },
        '&:focus':{
          outline: '2px solid #8cc63f'
        }
    },
    Text: {
        width: '135px',
        color: 'white',
        border: '0.5px solid white',
        backgroundColor: 'white',
        borderRadius: '5px',
        marginTop: '3.5px',
        marginRight: '3.5px',
        height: '1.9em',
        marginLeft: '3px'
    }
}));

const Header = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  //const isMenuOpen = Boolean(anchorEl);
  //const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [state, setState] = React.useState({
    right: false,
    switchTLPanel: false,
    slide: false,
  });
  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  // function handleProfileMenuOpen(event) {
  //   setAnchorEl(event.currentTarget);
  // }

  // function handleMobileMenuClose() {
  //   setMobileMoreAnchorEl(null);
  // }

  // function handleMenuClose() {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // }
  
  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = "primary-search-account-menu-mobile";
  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      // onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem> 
          <Select
            value={props.currentTimeline}
            onChange={props.handleChangeTimeline}
            className={classNames(classes.icon)}
            classes={{
              icon: classes.icon,
              root: classes.Select
            }}
            onOpen={() =>  setState({ ...state, 'slide': true })}
            onClose={() =>  setState({ ...state, 'slide': false })}
            renderValue={selected => {
              return props.currentTimeline;
            }}
          >  
            {props.timelines.map((timeline) => {
                return(
                    <MenuItem tabIndex={0} className={classes.listItem}  key={timeline} value={timeline} onClick={props}>{timeline}</MenuItem>
                )
            })}
          </Select>
        </ListItem>
        {props.params.timelineID ? (
          <div>
        <ListItem>
          <Button 
            className={classes.Button}
            onClick={props.handleScrolling} aria-label="Auto-Scroll"
        >
            {
                props.isPaused ?
                (
                  <span> 
                    Start Scroll &nbsp;
                     <i className="fas fa-angle-double-right" arial-label='Auto-Scroll' title='Auto-Scroll'></i>

                  </span>
                  ) 
                : (
                    <span> Pause Scroll &nbsp; <i className="fas fa-pause" title='Pause'></i> </span>
                  )
            }
        </Button>
        </ListItem>
        <ListItem>
          <Button 
            className={classes.Button}
            onClick={props.handleShare} aria-label="Share"
        >
            <span> Share &nbsp; <i className="fas fa-share-alt" title='Share'></i> </span>
        </Button>
        </ListItem>
        <ListItem className="search-section">{props.search}</ListItem>
        </div>
        ): null}
      </List>
    </div>
  );

  let right_header = null

  if(props.params.timelineID) {
     right_header = (
      <div className={classNames("right-header", "header-block", "right")}>
        <span className="right-header-block" tabIndex={'0'}> 
          {props.search}
        </span>  
        <span className="right-header-block"> 

              <Button 
                  className={classes.Button}
                  onClick={props.handleScrolling} aria-label="Auto-Scroll"
              >
                  {
                      props.isPaused ? 
                      (<i className="fas fa-angle-double-right" title='Auto-Scroll'></i>) 
                      : (<i className="fas fa-pause" title='Pause'></i>)
                  }
              </Button>
          </span>
          <span className="right-header-block"> 
              <Button 
                  className={classes.Button} 
                  onClick={props.handleFullScreen} aria-label="Full Screen"
              >
                      {
                          !props.isFullScreen ? 
                          (<i title="Full Screen" className="fas fa-expand-arrows-alt" />) 
                          : (<i className="fas fa-compress-arrows-alt" title='Exit Full Screen'></i>)
                      }
              </Button>
          </span>
          <span className="right-header-block"> 
              <Button 
                  className={classes.Button}
                  onClick={props.handleShare} aria-label="Share"
              >
                  <i className="fas fa-share-alt" title='Share'></i>
              </Button>
          </span>
          < Share share={props.openShare} handleShare={props.handleShare} />
      </div>
    )
  }
  return (
    <div className={classes.grow}>
      <AppBar className={classes.appBar} position="absolute" >
          <div className={classNames(classes.logo,"header-block")}>
  
            <Link to='/' ><img src={desktopLogo} alt="National September 11 Memorial and Museum Logo" /></Link>
          </div>
          <div className={classNames(classes.sectionDesktop, "header-block", "header-block-last")}>
              <Select
                    
                    value={props.currentTimeline}
                    onChange={props.handleChangeTimeline}
                    disableUnderline={true} 
                    className={classNames(classes.icon)}
                    classes={{
                      icon: classes.icon,
                      root: classes.Select
                    }}
                    
                    onOpen={() =>  setState({ ...state, 'slide': true })}
                    onClose={() =>  setState({ ...state, 'slide': false })}
                    renderValue={selected => {
                        return props.currentTimeline;
                    }}
                >  
                    {props.timelines.map((timeline) => {
                        return(
                            
                            <MenuItem tabIndex={0} className={classes.listItem} key={timeline} value={timeline}>{timeline}</MenuItem>
                        )
                    })}
                </Select>
              
                {props.params.timelineID ? (
                  <div>
              {right_header}
              </div>
                  
              ): null}
          </div>

            <div className={classes.sectionMobile} id="mobile-section">
              <IconButton
              className={classes.iconButton}
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={toggleDrawer('right', true)}
              color="inherit"
              >
              <MenuIcon id="hamburger"/>
              </IconButton>
            </div>
      
      </AppBar>
      <SwipeableDrawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          {sideList('right')}
          
        </SwipeableDrawer>
        

    </div>
  );
}

export default Header