// ClickyTracker.js

import React, { useEffect } from 'react';

const ClickyTracking = ({ siteId }) => {
  useEffect(() => {
    if (!siteId) {
      console.error('Clicky site ID not provided');
      return;
    }

    // Insert Clicky tracking code here
    (function() {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = '//static.getclicky.com/js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(s);
    })();
    
    window.clicky_site_ids = [siteId];
  }, [siteId]);

  return null; // Clicky tracking code doesn't need to render anything
}

export default ClickyTracking;