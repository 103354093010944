/**
 * This fucntion is used to check if the URL is valid, specifically if the params in the URL valid.
 * This function first checks the length of the params to determine what is being requested.
 * * Length of 4 = image or video
 * * Length of 3 = audio or invalid if audios now include mediaID
 * * Length of 2 = invalid because that would mean the URL is timelineName/mediaType
 * * Length of 1 = timeline
 * For each length, it does its own verification check.
 * Depending on the result, the component that uses this function will either redirect the user
 * to the error page or proceed to display the requested data.
 * 
 * @param {Object} params - contains the URL params such as timeline, mediaType, entryID, and mediaID
 * @param {Object} timeline - contains the data for the current timeline
 * @param {Object} hash - contains the hashtable for the current timeline
 * @return {Boolean} Returns a Boolean value depending on whether the check passed or failed
 */
function checkURL(params, timeline, hash) {
    if(Object.keys(params).length === 4) {
        if(Object.keys(timeline).includes('timeline' + params.timelineID)) {
            // replace this with the code below if using mediaID with audio
            if(['images', 'videos', 'audios'].includes(params.mediaType)) {
                if(Object.keys(hash['timeline' + params.timelineID]).includes(params.entryid + '_' + params.mediaid)) {
                    return true
                } else {
                    return false
                }
            }

            // with this 
            // if(['images', 'videos', audios].includes(params.mediaType)) {
            //     if(Object.keys(hash['timeline' + params.timelineID]).includes(params.entryid + '_' + params.mediaid)) {
            //         return true
            //     } else {
            //         return false
            //     }
            // }
            //--------------------------------------------------
        }
    // remove the else if code if audios now uses a mediaID
    // } else if (Object.keys(params).length === 3) {
    //     if(Object.keys(timeline).includes('timeline' + params.timelineID)) {
    //         if(['audios'].includes(params.mediaType)) {
    //             let entryids = Object.keys(hash['timeline' + params.timelineID]).map((id) => id.split('/')[0])
    //             if(entryids.includes(params.entryid)) {
    //                 return true
    //             } else {
    //                 return false
    //             }
    //         }
    //     }
    } else if (Object.keys(params).length === 2) { 
        return false
    } 
    // Up to here ------------------------------------

    //replace with the following:
    // else if(Object.keys(params).length === 3 || Object.keys(params).length === 2) {
    //     return false
    // }
    else if(Object.keys(timeline).includes('timeline' + params.timelineID)) {
        return true
    } else {
        return false
    }
}

export default checkURL